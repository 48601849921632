import React, { HTMLAttributes } from "react";
import button from "./button.module.scss";
type ButtonProps = {
  full?: boolean,
  children: any,
  small? : boolean ,
  theme ? : "primary" | "secondary" | "border"
};
export const Button: React.FC<
  React.HTMLAttributes<HTMLDivElement & HTMLButtonElement & HTMLAnchorElement> &
    ButtonProps
> = ({ className, ...props }) => {
  let ele = React.cloneElement(props.children, {
    className: `${button.button} ${!props.theme || props.theme === "primary" ?  button.primary : (props.theme === "border" ? button.border : button.secondary ) } ${props.full ? button.full : ""} ${
      className || ``
    } ${props.small ? button.small :''}`.trim(),
  });
  return ele;
};
export const AjaxLoader : React.FC=( props )=>{
  return <i className={`icon-spinner ${button.loader}`}></i>
}
