export interface Auth {
  loggedIn : boolean ,
  key : string
 }
export enum AuthActions {
  LOGGED_IN = 'LOGGED_IN' ,
  SESSION_EXPIRED = "SESSION_EXPIRED"
}
interface AuthActionTypes<T,P>{
  type : T ,
  payload : P
}
export type AuthAction = AuthActionTypes<typeof AuthActions.LOGGED_IN , Auth >|AuthActionTypes<typeof AuthActions.SESSION_EXPIRED , Auth >