import React from "react";
import { ErrorMessage } from 'formik';
import error from "./error.module.scss";
interface Props {
    name: string,
    style: "primary" | "secondary"
}
export const FormError: React.FC<Props> = (props) => {
    return <ErrorMessage name={props.name} render={(msg) => {
        if (!msg) {
            return <React.Fragment />
        }
        return (<div className={`${error.error} ${(!props.style || props.style == "secondary") ? error.secondary : error.primary}`}>
            <span>{msg}</span>
        </div>);
    }} />

}
interface NonFieldErrorProps {
    error: string | boolean
}
export const NonFieldError: React.FC<NonFieldErrorProps> = (props) => {
    if (!props.error) {
        return <React.Fragment />
    }
    return <div className={error.no_field_error}>* {props.error}</div>
}