import {  AuthAction, AuthActions } from "../model/auth.model";
export default (state = {}, action: AuthAction) => {
	switch (action.type) {
		case AuthActions.LOGGED_IN:
			return {
				...state ,
				session_expired: false ,
				...action.payload,
			};
			case AuthActions.SESSION_EXPIRED :
				return {
					session_expired : true 
				}
			default: 
			return state ;
	}
};
