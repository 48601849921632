export default (state = {}, action: any) => {
	switch (action.type) {
		case "SESSION_EXPIRED":
			return {};
			case "DASHBOARD_LOADING" :
				return {
					...state ,
					dashboard_loading : true 
				}
            case "DASHBOARD":
			return {
                ...state ,
				dashboard_loading : false ,
                ...action.payload 
            };
		default:
			return state;
	}
};
