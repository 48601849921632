export default (state = {}, action: any) => {
	switch (action.type) {
		case "SESSION_EXPIRED" :
			return {} ;
		case "COMPLAINTS":
			return {
				...state,
				...action.payload,
				COMPLAINTS_LOADING: false,
			};
			case "CATALOUGE_LOADING":
				return {
					...state,
					CATALOUGE_LOADING: true,
				};
			case "CATALOUGES":
				return {
					...state ,
					...action.payload,
					CATALOUGE_LOADING: false,
				}
				case "LOCATION_LOADING":
					return {
						...state,
						LOCATION_LOADING: true,
					};
				case "LOCATION":
					return {
						...state ,
						...action.payload,
						LOCATION_LOADING: false,
					}

				case "BRANDS_LOADING":
				return {
					...state,
					BRANDS_LOADING: true,
				};
			case "BRANDS":
				return {
					...state ,
					...action.payload,
					BRANDS_LOADING: false,
				}
				case "CATEGORY_LOADING":
				return {
					...state,
					BRANDS_LOADING: true,
				};
			case "CATEGORIES":
				return {
					...state ,
					...action.payload,
					CATEGORY_LOADING: false,
				}
		case "COMPLAINTS_LOADING":
			return {
				...state,
				COMPLAINTS_LOADING: true,
			};
		case "TECHNICIANS":
			return {
				...state,
				...action.payload,
				TECHNICIANS_LOADING: false,
			};
			case "DEALERS_LOADING":
			return {
				...state,
				DEALERS_LOADING: true,
			};
		case "DEALERS":
			return {
				...state,
				...action.payload,
				DEALERS_LOADING: false,
			};


			case "DETAILS_LOADING":
			return {
				...state,
				DETAILS_LOADING: true,
			};
		case "DETAILS":
			return {
				...state,
				...action.payload,
				DETAILS_LOADING: false,
			};


		case "TECHNICIANS_LOADING":
			return {
				...state,
				TECHNICIANS_LOADING: true,
			};
		case "LOADING_UNASSIGNED":
			return {
				...state,
				LOADING_UNASSIGNED: true,
			};
		case "UNASSIGNED_COMPLAINTS":
			return {
				...state,
				LOADING_UNASSIGNED: false,
				...action.payload,
			};
			case 'SESSION_EXPIRED':
				return {
				}
				
				
		default:
			return state;
	}
};
