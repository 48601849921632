import { Auth } from "../model/auth.model";
class Helpers {
	queryString = function (obj: any, encode = true) {
		var str = [];
		for (var p in obj)
			if (Object.prototype.hasOwnProperty.call(obj, p)) {
				if (obj[p]) {
					if (encode) {
						str.push(
							encodeURIComponent(p) +
								"=" +
								encodeURIComponent(obj[p])
						);
					} else {
						str.push(p + "=" + obj[p]);
					}
				}
			}
		return str.join("&");
	};
	error(errorObject: any) {
		if (Object.keys(errorObject).length > 0) {
			let err: any = {};
			for (var key in errorObject) {
				err[key] = errorObject[key].join(" ");
			}
			return err;
		} else {
			return {};
		}
	}
	formatNumber(number: string | number): string {
		let num: number = parseInt((number || "").toString());
		if (!num || isNaN(num)) {
			return "";
		}
		return num.toLocaleString("en-US", { maximumFractionDigits: 2 });
	}
	setAuth(obj: Auth): void {
		if (obj.loggedIn) {
			localStorage.setItem("loggedIn", "true");
			localStorage.setItem("key", obj.key);
			localStorage.setItem("lastLoggedIn", `${new Date().getTime()}`);
		} else {
			localStorage.clear();
		}
	}
	getAuthKey(): string {
		return localStorage.getItem("key") || "";
	}
	isLoggedIn() {
		return !!this.getAuthKey();
	}
	query() {
		const params: any = new URLSearchParams(window.location.search),
			query: any = {};
		for (const param of params) {
			query[param[0]] = param[1];
		}
		return query;
	}
	chunk(array:any, size:number ) {
		let result = []
		for (let i = 0; i < array.length; i += size) {
			let chunk = array.slice(i, i + size)
			result.push(chunk)
		}
		return result ;
	}
}
export default new Helpers();
