import React, { Suspense } from "react";
import admin from "./admin.module.scss";
const OtherComponent = React.lazy(() => import("./admin"));
type DashboardProps = {
  key?: string,
};
const DashboardLoader = () => {
  return <div></div>;
};
export const DashboardWrapper: React.FC<DashboardProps> = (props) => {
  return (
    <div className={admin.admin_dashboard}>
      <Suspense fallback={<DashboardLoader />}>
        <OtherComponent />
      </Suspense>
    </div>
  );
};
