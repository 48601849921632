import * as React from "react";
import { Route, Router } from "react-router-dom";
import { history } from "./configureStore";
import { withRoot } from "./withRoot";
import Login from "./pages/login/login";
import { DashboardWrapper } from "./pages/dashboard/dashboard";
import Helpers from "./utils/helpers";
import {Redirect } from "react-router-dom" ;
import "./index.scss";
function App() {
	return (
	<Router history={history}>
			<PrivateRoute exact={true} path="/" secured={false} component={Login} />
			<PrivateRoute path="/admin" secured={true} component={DashboardWrapper} />
		</Router>
	);
}
const PrivateRoute = ({ component: Component, allowSession, ...rest }: any) => {
	
	return (<Route
		{...rest}
		render={(props) => {
			let isLoggedIn = Helpers.isLoggedIn() ,
				path = props.match.path;
				if( isLoggedIn && path === "/"){
					return <Redirect {...props} to={{pathname:'/admin/dashboard/'}} />
				}else if( !isLoggedIn && path!=="/"){
					return <Redirect {...props} to={{pathname:'/'}} />	
				}else{
					return <Component {...props} />;
				}
		}}
	/>);
}
export default withRoot(App);
