import { MESSAGES } from "../const/messages";
export default (state = {}, action: any) => {
	switch (action.type) {
		case "SESSION_EXPIRED":
			return {
				http_error: true,
				type: "SESSION_EXPIRED",
				message: `${MESSAGES.SESSION_EXPIRED}`,
			};

		case "SERVER_ERROR":
			return {
				http_error: true,
				type: "SERVER_ERROR",
				message: `${MESSAGES.SERVER_ERROR}`,
			};
		case "UNKNOWN_ERROR":
			return {
				http_error: true,
				type: "UNKNOWN_ERROR",
				message: `${MESSAGES.SERVER_ERROR}`,
			};
		default:
			return state;
	}
};
