import { Dispatch } from 'redux';
import { Auth , AuthActions } from "../model/auth.model";
import Helpers from "../utils/helpers";
export const ActionAuth = {
    login : (payload: Auth ) => (dispatch : any ): Dispatch => {
        console.log('Here');
        Helpers.setAuth( payload );
        return dispatch({
            type: AuthActions.LOGGED_IN,
            payload: payload
        });
    }
}