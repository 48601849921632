import React from "react";
import { connect } from "react-redux";
import login from "./login.module.scss";
import { Link } from "react-router-dom" ;
import { LOGIN_SCHEMA, INITIAL_VALUES } from "./schema";
import { FormError, NonFieldError } from "../../components/form-error";
import { Formik } from "formik";
import { Button, AjaxLoader } from "../../components/button";
import logo from "../../assets/logo.svg";
import { UserService } from "../../services/user.service";
import Helpers from "../../utils/helpers";
import { MESSAGES } from "../../const/messages";
import { Redirect } from "react-router-dom";
import { ActionAuth } from "../../actions/auth.actions";
type LoginState = {
  nonFieldError: string | boolean,
  loggedIn: boolean,
  type: "password" | "text",
};
type LoginProps = {
  login : any ,
  auth : any 
};
class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: any) {
    super(props);
    this.state = {
      nonFieldError: "",
      loggedIn: false,
      type: "password",
    };
  }
  validate = () => {
    if (this.state.nonFieldError) {
      this.setState({
        nonFieldError: false,
      });
    }
  };
  submitForm = (values: object, { setErrors, setSubmitting }: any) => {
    let status: any;
    UserService.login(values)
      .then((res: any) => {
        status = res.status;
        return res;
      })
      .then((res: any) => res.json())
      .then((res) => {
        setSubmitting(false);
        if (status !== 200) {
          if (res.non_field_errors) {
            this.setState({
              nonFieldError: res.non_field_errors.join(" "),
            });
          } else {
            let errors: any = Helpers.error(res);
            setErrors(errors);
          }
        } else {
          localStorage.setItem("loggedIn", "true");
          this.props.login({
            loggedIn : true ,
            key  :res.key
          })
        }
      })
      .catch((err: any) => {
        setSubmitting(false);
        this.setState({
          nonFieldError: MESSAGES.unknown,
        });
      });
  };
  togglePassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  };
  render() {
    if (this.props.auth && this.props.auth.loggedIn ) {
      return <Redirect to="/admin/dashboard/" />
    }
    return (
      <div className={login.wrapper}>
        <div className={login.box}>
          <Formik
            initialValues={INITIAL_VALUES}
            validate={this.validate}
            onSubmit={this.submitForm}
            validationSchema={LOGIN_SCHEMA}
          >
            {({
              handleChange,
              handleBlur,
              values,
              touched,
              handleSubmit,
              isSubmitting,
              errors,
            }) => {
              return (
                <form className="ec-form" onSubmit={handleSubmit}>
                  <div className="container-fluid">
                    <div className="row mb-5">
                      <div className="col-12">
                        <img
                          src={logo}
                          alt="EasyCare"
                          title="Easy Care"
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center mb-2">
                        <div className={login.input_wrapper}>
                          <input
                            className={
                              touched.username && errors.username
                                ? "has-error"
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            type="text"
                            placeholder="User ID"
                            name="username"
                            id="username"
                            tabIndex={1}
                          />
                          <div className={login.input_icon}>
                            <i className="icon-user"></i>
                          </div>
                        </div>
                        <div className={login.error_wrapper}>
                          <FormError name="username" style="primary" />
                        </div>
                      </div>
                      <div className="col-12 text-center mb-2">
                        <div className={login.input_wrapper}>
                          <input
                            className={
                              touched.password && errors.password
                                ? "has-error"
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="false"
                            name="password"
                            id="password"
                            type={this.state.type}
                            value={values.password}
                            tabIndex={2}
                            placeholder="Password"
                          />
                          <div
                            className={login.input_icon}
                            onClick={this.togglePassword}
                          >
                            <i
                              className={`icon-eye-${this.state.type === "password"
                                  ? "open"
                                  : "close"
                                }`}
                            ></i>
                          </div>
                        </div>
                        <div className={login.error_wrapper}>
                          <FormError name="password" style="primary" />
                        </div>
                        {this.state.nonFieldError && (
                          <div className={login.error_wrapper} data-test="12">
                            <NonFieldError error={this.state.nonFieldError} />
                          </div>
                        )}
                      </div>
                      <div className="col-12 text-center">
                        <Button>
                          <button type="submit" tabIndex={3}>
                            Login {isSubmitting && <AjaxLoader />}
                          </button>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  login : (params:any)=>{
    dispatch(ActionAuth.login(params));
  }
});
const mapStateToProps = (state: any) => ({
  ...state
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
