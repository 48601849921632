import Helpers from "./helpers";
import { CONFIG } from "../config/config";
interface HttpProps {
	domain: string;
}
class Http {
	_url(path: string) {
		return `${CONFIG.api}${path}`;
	}
	_(url: string, payload: object = {}, absoluteUrl: boolean = !1) {
		let self = this;
		return new Promise((resolve, reject) => {
			let h = new Headers({
				"Content-Type": "application/json",
			});
			if (Helpers.getAuthKey()) {
				h.append("Authorization", `Token ${Helpers.getAuthKey()}`);
			}
			fetch(!absoluteUrl ? this._url(url) : url, {
				...{
					headers: h,
					credentials: "include",
				},
				...payload,
			})
				.then((response) => {
					self.success.call(self, resolve, reject, response);
				})
				.catch((error) => {
					self.reject.call(self, resolve, reject, error);
				});
		});
	}
	post(url = "/", params = {}) {
		return this._(url, {
			body: JSON.stringify(params),
			method: "POST",
		});
	}
	delete(url = "/", params = {}, absoluteUrl = false) {
		return this._(
			url,
			{
				body: JSON.stringify(params),
				method: "DELETE",
			},
			absoluteUrl
		);
	}
	patch(url = "/", params = {}, absoluteUrl = false) {
		return this._(
			url,
			{
				body: JSON.stringify(params),
				method: "PATCH",
			},
			absoluteUrl
		);
	}

	get(url = "/", params = {}) {
		if (Object.keys(params).length > 0) {
			url += `?${Helpers.queryString(params)}`;
		}
		return this._(url);
	}
	reject(resolve: any, reject: any, error: any) {
		reject(error);
	}
	success(resolve: any, reject: any, response: any) {
		let code = response.status,
			success = [200, 204, 201, 400, 406];
		if (success.indexOf(code) > -1) {
			return resolve(response);
		}
		reject(response);
	}
}
export default new Http();
