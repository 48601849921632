import { History } from "history";
import { combineReducers } from "redux";
import auth from "./auth.reducer";
import admin from "./admin.reducer" ;
import select from "./select.reducer" ;
import toast from "./toast.reducer";
import error from "./error.reducer" ;
import dashboard from "./dashboard.reducer" ;
export default (history:History) =>
	combineReducers({
		auth ,
		admin,
		select,
		toast ,
		error ,
		dashboard
});
