export default (state = {}, action: any) => {
	switch (action.type) {
		case "SELECT_SEARCH":
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};
